<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <!--/ Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col> -->
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col> -->
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <!-- b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col> -->
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import store from '@/store'

import { getUserData } from '@/auth/utils'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
/* import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue' */

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    /* EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions, */
  },
  data() {
    return {
      data: {},
      isBusy: false,
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
    this.getReport()
  },
  methods: {
    async getReport() {
      this.isBusy = true
      const that = this
      await store
        .dispatch('report/GET_MAIN_BOARD')
        .then(response => {
          that.data.statisticsItems = [
            {
              icon: 'TrendingUpIcon',
              color: 'light-primary',
              title: response.countEI,
              subtitle: 'I.E. Focalizadas',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: response.countID,
              subtitle: 'Aliados',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'FlagIcon',
              color: 'light-danger',
              title: response.countRP,
              subtitle: 'Lugares de Riesgo',
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'FilePlusIcon',
              color: 'light-success',
              title: response.countS,
              subtitle: 'Casos',
              customClass: '',
            },
          ]
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
